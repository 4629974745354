import { useState } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import LayoutImporterCell from 'src/components/LayoutImporter/LayoutImporterCell'
import Tab from 'src/components/Library/Tab'
import Tabs from 'src/components/Library/Tabs'
import PageHeader from 'src/components/PageHeader/PageHeader'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'

enum IMPORTERS {
  WORKFLOW_LAYOUTS = 'layouts',
  AUTOMATIONS = 'automations',
}

const HubsImportersPage = () => {
  const [activeTab, setActiveTab] = useState(IMPORTERS.WORKFLOW_LAYOUTS)

  // Get Feature Flag Status
  const [importerLive] = useLiveFeature(FLAG_FEATURES.HUBS_IMPORTERS)

  if (!importerLive) {
    navigate(routes.homeApp())
  }

  return (
    <>
      <Metadata title="Hubs Importers" description="Hubs Importers page" />
      <PageHeader
        title={'Importers'}
        parentDataTestId="hubs-importers-page-header"
      />
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => {
          if (newValue) {
            setActiveTab(String(newValue) as IMPORTERS)
          }
        }}
      >
        <Tab label="HubDash Layouts" value={IMPORTERS.WORKFLOW_LAYOUTS}></Tab>
        <Tab disabled label="Automations" value={IMPORTERS.AUTOMATIONS}></Tab>
      </Tabs>
      <div className="p-4">
        {activeTab === IMPORTERS.WORKFLOW_LAYOUTS && <LayoutImporterCell />}
      </div>
    </>
  )
}

export default HubsImportersPage
