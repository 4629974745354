import { WorkflowsLayout } from './LayoutImporterCell'

export type WorkflowsLayoutByClientId = {
  [clientId: string]: WorkflowsLayout[]
}

export const groupItemsByClientId = (
  layouts: WorkflowsLayout[],
): WorkflowsLayoutByClientId => {
  const workflowsLayoutByClientId = layouts.reduce(
    (layoutsByClientId, currentLayout) => {
      const returnObjKeys = Object.keys(layoutsByClientId)

      const currentClientId = currentLayout.clientId

      if (!returnObjKeys.includes(String(currentClientId))) {
        layoutsByClientId[currentClientId] = []
      }

      layoutsByClientId[currentClientId].push(currentLayout)

      return layoutsByClientId
    },
    {},
  )

  return workflowsLayoutByClientId
}
