import { FC, useState } from 'react'

import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import { captureException } from '@sentry/browser'
import {
  CreateHubDashLayoutAsStafflinkInput,
  type BaserowSelection,
  type ImportLayout,
  type ImportLayoutVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from '../Library/Button/Button'

import BaseSelectorList from './BaseSelectorList'
import ClientSelectorList from './ClientSelectorList'
import { WorkflowsClient, WorkflowsLayout } from './LayoutImporterCell'
import LayoutSelectorList from './LayoutSelectorList'
import { IMPORT_LAYOUT } from './queries'

interface LayoutImporterProps {
  layouts: WorkflowsLayout[]
  clients: WorkflowsClient[]
  refetch: () => Promise<unknown>
}

const LayoutImporter: FC<LayoutImporterProps> = ({
  layouts,
  clients,
  refetch,
}) => {
  const [layoutToCopy, setLayoutToCopy] = useState<WorkflowsLayout>(null)
  const [clientToCopyTo, setClientToCopyTo] = useState<WorkflowsClient>(null)
  const [importLoading, setImportLoading] = useState<boolean>(false)
  const [copyWithBaseData, setCopyWithBaseData] =
    useState<BaserowSelection>(null)

  const [importLayout] = useMutation<ImportLayout, ImportLayoutVariables>(
    IMPORT_LAYOUT,
    {
      onError: (error) => {
        toast.error(error?.message)
        captureException(error, {
          extra: {
            message: 'Import Layout: failed',
          },
        })
        setImportLoading(false)
      },
    },
  )

  const finishImport = async () => {
    toast.success('Layout successfully Imported', { duration: 5000 })
    setImportLoading(false)
    setLayoutToCopy(null)
    setClientToCopyTo(null)
    setCopyWithBaseData(null)

    await refetch()
  }

  const handleCopyLayout = async () => {
    setImportLoading(true)

    const createLayoutInput: CreateHubDashLayoutAsStafflinkInput = {
      clientId: clientToCopyTo?.id,
      name: layoutToCopy?.name,
      layoutData: layoutToCopy?.layoutData,
      userFilterType: layoutToCopy?.userFilterType,
      allowUserFilterOverride: layoutToCopy?.allowUserFilterOverride,
    }

    await importLayout({
      variables: {
        input: {
          originalLayoutId: layoutToCopy.id,
          createLayoutInput,
          copyWithBaseData: copyWithBaseData,
        },
      },
    })

    finishImport()
  }

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-row gap-6 py-16">
      <div className="w-full flex-shrink-0">
        <div className="flex w-full flex-col gap-10">
          <div className="flex items-center gap-4">
            <span className="rounded-full bg-indigo-100 p-2">
              <ArrowsRightLeftIcon className="h-8 w-8 text-indigo-600" />
            </span>
            <p className="text-2xl">Import HubDash Layout to Client</p>
          </div>
          <LayoutSelectorList
            layouts={layouts}
            clients={clients}
            layoutToCopy={layoutToCopy}
            setLayoutToCopy={setLayoutToCopy}
            importLoading={importLoading}
          />
          <ClientSelectorList
            clients={clients}
            clientToCopyTo={clientToCopyTo}
            setClientToCopyTo={setClientToCopyTo}
            importLoading={importLoading}
          />
          <BaseSelectorList
            importLoading={importLoading}
            copyWithBaseData={copyWithBaseData}
            setCopyWithBaseData={setCopyWithBaseData}
          />
          <div className="flex w-full justify-end">
            <Button
              onClick={handleCopyLayout}
              disabled={!layoutToCopy || !clientToCopyTo}
              fullWidth={false}
              loading={importLoading}
            >
              Start Import
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutImporter
