import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { Autocomplete, TextField } from '@mui/material'
import { captureException } from '@sentry/browser'
import {
  LoginToBaserowQuery,
  type BaserowSelection,
  type LoginToBaserowQueryVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import Spinner from 'src/components/Library/Loading'

import { QUERY } from '../HubDash/HubDashCell'
import { getUserBasesAndTables } from '../HubDash/lib/baserow/baserowApi'

interface BaseSelectorListProps {
  importLoading: boolean
  copyWithBaseData: BaserowSelection
  setCopyWithBaseData: Dispatch<SetStateAction<BaserowSelection>>
}

const BaseSelectorList: FC<BaseSelectorListProps> = ({
  importLoading,
  copyWithBaseData,
  setCopyWithBaseData,
}) => {
  const [baseOptions, setBaseOptions] = useState<BaserowSelection[]>([])
  const [baseLoading, setBaseLoading] = useState<boolean>(true)

  const [selectedBase, setSelectedBase] = useState<BaserowSelection>(null)
  const [baseInput, setBaseInput] = useState<string>('')

  const [getBrToken] = useLazyQuery<
    LoginToBaserowQuery,
    LoginToBaserowQueryVariables
  >(QUERY)

  useEffect(() => {
    const getAvailableBases = async () => {
      try {
        // Get user token
        const getTokenResult = await getBrToken()
        const token = getTokenResult?.data?.loginToBaserow?.jwt

        // Get available Bases
        const basesData = await getUserBasesAndTables({
          token,
        })

        const baseOptions: BaserowSelection[] = basesData?.map((base) => {
          return {
            value: base?.id,
            label: base?.name,
            baseId: base?.id,
            baseName: base?.name,
            workspaceId: base?.workspace?.id,
            workspaceName: base?.workspace?.name,
          }
        })

        setBaseOptions(baseOptions)
      } catch (err) {
        toast.error('There was an error fetching Baserow Bases.', {
          duration: 5000,
        })
        captureException(err, {
          extra: {
            message: 'Layout Importer: Failed to fetch Baserow Bases.',
          },
        })
      }
      setBaseLoading(false)
    }
    getAvailableBases()
  }, [])

  useEffect(() => {
    setCopyWithBaseData(selectedBase)
  }, [selectedBase])

  useEffect(() => {
    if (!copyWithBaseData) {
      setSelectedBase(null)
    }
  }, [copyWithBaseData])

  return (
    <div className="flex flex-col gap-2">
      <p className="flex items-center gap-2 pl-2 text-xl text-gray-500">
        3. Connect to Base <span className="text-xs italic">(Optional)</span>
      </p>
      <div className="flex flex-wrap items-center gap-6 rounded border bg-white p-6">
        <p className="w-[200px] text-gray-500">Select a Base</p>
        <div className="min-w-[300px] flex-grow">
          <Autocomplete
            disabled={importLoading || baseLoading}
            value={selectedBase}
            onChange={(_event: any, newValue: any) => {
              setSelectedBase(newValue)
            }}
            inputValue={baseInput}
            onInputChange={(_event, newInputValue) => {
              setBaseInput(newInputValue)
            }}
            id="select-a-client"
            options={baseOptions}
            fullWidth
            groupBy={(option) => option?.workspaceName}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  baseLoading ? (
                    <p className="flex gap-4">
                      Loading Options <Spinner size="x-small" />
                    </p>
                  ) : (
                    'Base'
                  )
                }
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default BaseSelectorList
