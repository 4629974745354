import { Dispatch, FC, SetStateAction } from 'react'

import { FormControlLabel, Radio } from '@mui/material'

import { Accordion } from '../Library/Accordion'

import { WorkflowsClient, WorkflowsLayout } from './LayoutImporterCell'
import { groupItemsByClientId } from './utils'

interface LayoutSelectorListProps {
  layouts: WorkflowsLayout[]
  clients: WorkflowsClient[]
  layoutToCopy: WorkflowsLayout
  setLayoutToCopy: Dispatch<SetStateAction<WorkflowsLayout>>
  importLoading: boolean
}

const LayoutSelectorList: FC<LayoutSelectorListProps> = ({
  layouts,
  clients,
  layoutToCopy,
  setLayoutToCopy,
  importLoading,
}) => {
  const layoutsByClientId = groupItemsByClientId(layouts)

  return (
    <div className="flex flex-col gap-2">
      <p className="pl-2 text-xl text-gray-500">1. Select a layout</p>
      <div className="flex flex-col gap-1 rounded border bg-white p-6">
        {Object.entries(layoutsByClientId).map((clientLayoutArr) => {
          const client = clients.find(
            (client) => String(client.id) === clientLayoutArr[0],
          )
          const layouts = clientLayoutArr[1]
          return (
            <ClientLayoutAccordion
              client={client}
              layouts={layouts}
              layoutToCopy={layoutToCopy}
              setLayoutToCopy={setLayoutToCopy}
              importLoading={importLoading}
              key={client?.id}
            />
          )
        })}
      </div>
    </div>
  )
}

export default LayoutSelectorList

interface ClientLayoutAccordionProps {
  layouts: WorkflowsLayout[]
  client: WorkflowsClient
  layoutToCopy: WorkflowsLayout
  setLayoutToCopy: Dispatch<SetStateAction<WorkflowsLayout>>
  importLoading: boolean
}

const ClientLayoutAccordion: FC<ClientLayoutAccordionProps> = ({
  layouts,
  client,
  layoutToCopy,
  setLayoutToCopy,
  importLoading,
}) => {
  return (
    <Accordion
      borderstyle={`${layoutToCopy?.clientId === client?.id ? '!border-indigo-400' : '!border-gray-200'} !border !rounded before:hidden !overflow-hidden`}
      customstyle={`${layoutToCopy?.clientId === client?.id ? '!bg-indigo-100' : '!bg-gray-100'} `}
      title={`${client?.name} (${layouts?.length})`}
    >
      <div>
        {layouts?.map((layout) => {
          const cardCount = layout?.cardCountByLayoutId

          return (
            <FormControlLabel
              key={layout?.id}
              className={`m-0 w-full rounded border border-white hover:bg-gray-100 ${layout?.id === layoutToCopy?.id && '!border-indigo-200 !bg-indigo-50'}`}
              value={layout?.name}
              control={
                <Radio
                  disabled={importLoading}
                  checked={layoutToCopy?.id === layout?.id}
                  color="primary"
                  onClick={() => {
                    setLayoutToCopy(layout)
                  }}
                />
              }
              label={
                <p>
                  {layout?.name}
                  <span className="pl-4 text-sm text-gray-400">
                    {cardCount} Cards
                  </span>
                </p>
              }
            />
          )
        })}
      </div>
    </Accordion>
  )
}
